// @antd
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Typography } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import { PayProvider } from "../../contexts/PayContext";
import useAuth from "../../hooks/useAuth";
import usePay from "../../hooks/usePay";
import { PATH_PAGE } from "../../routes/paths";
import { cleanStorage } from "../../utils/storage";

function ContractSuccessCreateComponent() {
  const { checkPay, payPending, payInfo } = usePay();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    cleanStorage("FORM");
    cleanStorage("selectedCard");
  }, []);

  return (
    <Page title="Mi-LIC | Contrato Creado">
      <Row>
        <Col style={{ margin: "auto" }} xl={12} lg={12} md={24} sm={24} xs={24}>
          <section
            style={{
              marginTop: 30,
              marginBottom: 30,
              padding: "4% 10% 4% 10%",
            }}
          >
            {payPending ? (
              <Result
                icon={<ClockCircleOutlined className="rotate" />}
                title="Procesando pago, espere por favor..."
                extra={<></>}
              />
            ) : (
              <div>
                <Result
                  status="success"
                  title="Contrato creado exitosamente"
                  subTitle={
                    <>
                      <Typography.Text style={{ fontSize: 16 }}>
                        ¡Hola! Tu servicio fue realizado exitosamente. Puedes
                        revisar tu correo, a donde enviamos tu documento.
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ fontSize: 16 }}>
                        Por favor revisa en tu correo electrónico tu bandeja de
                        entrada y/o correo no deseado para encontrar tu
                        documento. Recuerda registrarnos como una dirección
                        segura.
                      </Typography.Text>
                      <br /> <br />
                      <Typography.Text style={{ fontSize: 16 }}>
                        ¡Gracias por tu confianza y por utilizar los servicios
                        de mi-lic!
                      </Typography.Text>
                    </>
                  }
                  extra={[
                    <Button href={"/"} type="primary" key="console">
                      <Typography.Text style={{ color: "#FFF" }}>
                        {" "}
                        Ir al inicio{" "}
                      </Typography.Text>
                    </Button>,
                    <>
                      {isAuthenticated && (
                        <Button
                          href={PATH_PAGE.archivero}
                          type="primary"
                          key="console"
                        >
                          <Typography.Text style={{ color: "#FFF" }}>
                            {" "}
                            Ver Archivero{" "}
                          </Typography.Text>
                        </Button>
                      )}
                    </>,
                    <Button href={PATH_PAGE.marketplace} key="buy">
                      Crear otro contrato
                    </Button>,
                  ]}
                />
              </div>
            )}
          </section>
        </Col>
      </Row>
    </Page>
  );
}

export default function ContractSuccessCreate() {
  return (
    <PayProvider>
      <ContractSuccessCreateComponent />
    </PayProvider>
  );
}
