// routes
import Router from "./routes";
// components
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import React, { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
 
export default function App() {
  const initFacebook = () => {};
  useEffect(() => {
    initFacebook();
  }, []);
  return (
    <MotionLazyContainer>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}>
        <Router />
      </GoogleOAuthProvider>
    </MotionLazyContainer>
  );
}
