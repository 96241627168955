// @antd
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Result, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { PayProvider } from "../../contexts/PayContext";
import useAuth from "../../hooks/useAuth";
import usePay from "../../hooks/usePay";
import { PATH_PAGE } from "../../routes/paths";
import { cleanStorage } from "../../utils/storage";

function CompletePayComponent() {
  const { checkPay, payPending, payInfo } = usePay();
  const [transactionID, setTransactionID] = useState(null);
  const { isAuthenticated } = useAuth();

  const [title, setTitle] = useState(
    "Pago de servicio completado exitosamente"
  );

  const [body, setBody] = useState(
    " ¡Hola! Tu servicio fue realizado exitosamente."
  );

  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      setTransactionID(new URLSearchParams(location.search).get("id"));
    }
  }, [location]);

  useEffect(() => {
    if (transactionID) checkPay(transactionID);
  }, [transactionID]);

  useEffect(() => {
    if (payInfo) {
      //   console.log(payInfo);
      let tl = "Pago de servicio completado exitosamente";
      let bl = " ¡Hola! Tu servicio fue realizado exitosamente.";
      if (payInfo.order_id) {
        if (payInfo.order_id.indexOf("document_for_reviewing") != -1) {
          bl =
            "¡Hola! Tu servicio fue solicitado y será realizado conforme a los tiempos contratados. El tiempo empezará a correr una vez que revisemos que tu documento es legible y coincide con las páginas pagadas.";
          tl = "Documentación enviada exitosamente";
        }

        if (payInfo.order_id.indexOf("contract") != -1)
          tl = "Contrato creado exitosamente";
        if (payInfo.order_id.indexOf("consultation") != -1) {
          tl = "Consulta creada exitosamente";
          bl =
            "¡Hola! Tu solicitud de asesoría fue generada exitosamente y se te entregará en el tiempo solicitado.";
        }
        if (payInfo.order_id.indexOf("videoconsulting") != -1)
          tl = "Consulta creada exitosamente";
      }
      setTitle(tl);
      setBody(bl);
    }
  }, [payInfo]);

  useEffect(() => {
    cleanStorage("FORM");
    cleanStorage("selectedCard");
  }, []);

  const getMessage = (payload) => {
    if (payload.status === "failed") {
      switch (payload.error_code) {
        case 3001:
          return "La tarjeta fue rechazada.";
          break;
        case 3002:
          return "La tarjeta ha expirado.";
          break;
        case 3003:
          return "La tarjeta no tiene fondos suficientes.";
          break;
        case 3004:
          return "La tarjeta ha sido identificada como una tarjeta robada.";
          break;
        case 3005:
          return "La tarjeta ha sido rechazada por el sistema antifraudes.";
          break;

        default:
          return "La tarjeta ha sido rechazada.";
          break;
      }
    }
  };

  return (
    <Page title="Mi-LIC | Pago Completado">
      <Row>
        <Col style={{ margin: "auto" }} xl={12} lg={12} md={24} sm={24} xs={24}>
          <section
            style={{
              marginTop: 30,
              marginBottom: 30,
              padding: "4% 10% 4% 10%",
            }}
          >
            {payPending ? (
              <Result
                icon={<ClockCircleOutlined className="rotate" />}
                title="Procesando pago, espere por favor..."
                extra={<></>}
              />
            ) : (
              <div>
                {payInfo && payInfo.status === "completed" ? (
                  <Result
                    status="success"
                    title={title}
                    subTitle={
                      <>
                        <Typography.Text
                          style={{ fontSize: 16, textAlign: "justify" }}
                        >
                          {body}
                        </Typography.Text>
                        <br />
                        <Typography.Text
                          style={{ fontSize: 16, textAlign: "justify" }}
                        >
                          Por favor revisa en tu correo electrónico tu{" "}
                          <strong>
                            bandeja de entrada y/o correo no deseado
                          </strong>{" "}
                          para encontrar nuestras indicaciones o documento.{" "}
                          <strong>
                            Recuerda registrarnos como una dirección segura.
                          </strong>
                        </Typography.Text>
                        <br /> <br />
                        <Typography.Text
                          style={{ fontSize: 16, textAlign: "justify" }}
                        >
                          ¡Gracias por tu confianza y por utilizar los servicios
                          de mi-lic!
                        </Typography.Text>
                      </>
                    }
                    extra={[
                      <Button href={"/"} type="primary" key="console">
                        <Typography.Text style={{ color: "#FFF" }}>
                          {" "}
                          Ir al inicio{" "}
                        </Typography.Text>
                      </Button>,
                      <>
                        {isAuthenticated && (
                          <Button
                            href={PATH_PAGE.archivero}
                            type="primary"
                            key="console"
                          >
                            <Typography.Text style={{ color: "#FFF" }}>
                              {" "}
                              Ver Archivero{" "}
                            </Typography.Text>
                          </Button>
                        )}
                      </>,
                      ,
                    ]}
                  />
                ) : (
                  <></>
                )}

                {payInfo && payInfo.status === "failed" ? (
                  <Result
                    status="error"
                    title="Error durante el pago"
                    subTitle={
                      <>
                        <Typography.Text style={{ fontSize: 16 }}>
                          El proceso de pago del servicio ha fallado, por favor
                          inténtelo más tarde
                        </Typography.Text>
                      </>
                    }
                    extra={[
                      <Button href={"/"} type="primary" key="console">
                        <Typography.Text style={{ color: "#FFF" }}>
                          {" "}
                          Ir al inicio{" "}
                        </Typography.Text>
                      </Button>,

                      ,
                    ]}
                  />
                ) : (
                  <></>
                )}

                {payInfo && payInfo.status === "in_progress" ? (
                  <Result
                    status="info"
                    title="El proceso de pago aún no ha terminado"
                    subTitle={
                      <>
                        <Typography.Text style={{ fontSize: 16 }}>
                          El proceso de pago aún no se ha completado, espere
                          unos minutos, y recargue la página para verificar
                        </Typography.Text>
                      </>
                    }
                    extra={[
                      <Button
                        href={PATH_PAGE.complete_pay + "?id=" + transactionID}
                        type="primary"
                        key="console"
                      >
                        <Typography.Text style={{ color: "#FFF" }}>
                          {" "}
                          Recargar página{" "}
                        </Typography.Text>
                      </Button>,

                      ,
                    ]}
                  />
                ) : (
                  <></>
                )}

                {payInfo &&
                payInfo.http_code &&
                payInfo.http_code !== 200 &&
                !payPending ? (
                  <Result
                    status="error"
                    title="Error durante el pago"
                    subTitle={
                      <>
                        <Typography.Text style={{ fontSize: 16 }}>
                          El proceso de pago del servicio ha fallado, por favor
                          inténtelo más tarde.
                          <br />
                          <br />
                          <strong
                            style={{
                              fontSize: 14,
                              fontFamily: "montserrat-regular",
                            }}
                          >
                            Detalles:
                          </strong>
                          <br />
                          <i>{payInfo.description}</i>
                        </Typography.Text>
                      </>
                    }
                    extra={[
                      <Button href={"/"} type="primary" key="console">
                        <Typography.Text style={{ color: "#FFF" }}>
                          {" "}
                          Ir al inicio{" "}
                        </Typography.Text>
                      </Button>,
                    ]}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </section>
        </Col>
      </Row>
    </Page>
  );
}

export default function CompletePay() {
  return (
    <PayProvider>
      <CompletePayComponent />
    </PayProvider>
  );
}
