import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import ContractSuccessCreate from "../pages/Contract/ContractSuccessCreate";
import FreeAuthGuard from "../guards/FreeAuthGuard";
import ReviewSuccessCreate from "../pages/Contract/ReviewSuccessCreate";
import { PATH_AUTH, PATH_PAGE } from "./paths";
import ConsultingWriteSuccessCreate from "../pages/Consulting/ConsultingWriteSuccessCreate";
import ConsultingVideoSuccessCreate from "../pages/Consulting/ConsultingVideoSuccessCreate";
import CompletePay from "../pages/Common/CompletePay";
import CompletePayStore from "../pages/Common/CompletePayStore";
import CalcSuccessCreate from "../pages/Calc/CalcSuccessCreate";
 
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.register,
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.forgotPassword,
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    // Main Routes
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: PATH_PAGE.term, element: <TermPage /> },
        { path: PATH_PAGE.privacy, element: <PrivacyPage /> },
        {
          path: PATH_PAGE.marketplace,
          element: (
            <FreeAuthGuard>
              <MarketPlacePage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.create_contact + "/:id",
          element: (
            <FreeAuthGuard>
              <ContractFormPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.review,
          element: (
            <FreeAuthGuard>
              <ContractReviewPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.review_other,
          element: (
            <FreeAuthGuard>
              <ContractReviewOtherPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.aboutus,
          element: (
            <FreeAuthGuard>
              <AboutPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.write_with_lawyer,
          element: (
            <FreeAuthGuard>
              <WriteWithLawyerPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.video_with_lawyer,
          element: (
            <FreeAuthGuard>
              <VideoWithLawyerPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.reprograme_video_with_lawyer + "/:id",
          element: (
            <FreeAuthGuard>
              <ReProgrameVideoWithLawyerPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.video_start_with_lawyer,
          element: (
            <AuthGuard>
              <VideoStartWithLawyerPage />
            </AuthGuard>
          ),
        },
        {
          path: PATH_PAGE.write_with_lawyer_successfull,
          element: (
            <FreeAuthGuard>
              <ConsultingWriteSuccessCreate />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.video_with_lawyer_successfull,
          element: (
            <FreeAuthGuard>
              <ConsultingVideoSuccessCreate />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.consulting_service,
          element: (
            <FreeAuthGuard>
              <InfoConsultingPage />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.review_pay,
          element: (
            <FreeAuthGuard>
              <ContractReviewPayPage />
            </FreeAuthGuard>
          ),
        },

        {
          path: PATH_PAGE.settlements,
          element: (
            <FreeAuthGuard>
              <CalcPage />
            </FreeAuthGuard>
          ),
        },

        {
          path: PATH_PAGE.settlements_create_successfull,
          element: (
            <FreeAuthGuard>
              <CalcSuccessCreate />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.create_contract_successfull,
          element: (
            <FreeAuthGuard>
              <ContractSuccessCreate />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.review_contract_successfull,
          element: (
            <FreeAuthGuard>
              <ReviewSuccessCreate />
            </FreeAuthGuard>
          ),
        },
        {
          path: PATH_PAGE.resetPassword,
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: PATH_PAGE.profile,
          element: (
            <AuthGuard>
              <Profile />
            </AuthGuard>
          ),
        },
        {
          path: PATH_PAGE.contact_list,
          element: (
            <AuthGuard>
              <ContactListPage />
            </AuthGuard>
          ),
        },
        {
          path: PATH_PAGE.archivero,
          element: (
            <AuthGuard>
              <Archivero />
            </AuthGuard>
          ),
        },
        {
          path: PATH_PAGE.programing_video,
          element: (
            <AuthGuard>
              <ProgramingVideoPage />
            </AuthGuard>
          ),
        },

        {
          path: PATH_PAGE.complete_pay,
          element: (
            <FreeAuthGuard>
              <CompletePay />
            </FreeAuthGuard>
          ),
        },

        {
          path: PATH_PAGE.complete_service,
          element: (
            <FreeAuthGuard>
              <CompletePayStore />
            </FreeAuthGuard>
          ),
        },

        {
          path: PATH_PAGE.contactus,
          element: (
            <FreeAuthGuard>
              <ContactUsPage />
            </FreeAuthGuard>
          ),
        },
        { path: "500", element: <Page500 /> },
        { path: PATH_PAGE.page404, element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to={PATH_PAGE.page404} replace /> },
      ],
    },
    { path: "*", element: <Navigate to={PATH_PAGE.page404} replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);

//CONTRACT
const MarketPlacePage = Loadable(
  lazy(() => import("../pages/Contract/MarketPlace"))
);
const ContractFormPage = Loadable(
  lazy(() => import("../pages/Contract/ContractForm"))
);
const ContractReviewPage = Loadable(
  lazy(() => import("../pages/Contract/ContractReview"))
);
const ContractReviewOtherPage = Loadable(
  lazy(() => import("../pages/Contract/ContractReviewOther"))
);
const ContractReviewPayPage = Loadable(
  lazy(() => import("../pages/Contract/ContractReviewPay"))
);

//CONSULTING
const WriteWithLawyerPage = Loadable(
  lazy(() => import("../pages/Consulting/WriteWithLawyer"))
);
const VideoWithLawyerPage = Loadable(
  lazy(() => import("../pages/Consulting/VideoWithLawyer"))
);
const ReProgrameVideoWithLawyerPage = Loadable(
  lazy(() => import("../pages/Consulting/ReProgrameVideoWithLawyer"))
);
const ProgramingVideoPage = Loadable(
  lazy(() => import("../pages/auth/ProgramingVideo"))
);

const VideoStartWithLawyerPage = Loadable(
  lazy(() => import("../pages/Consulting/VideoStartWithLawyer"))
);
const InfoConsultingPage = Loadable(
  lazy(() => import("../pages/Consulting/Info"))
);

//CONSULTING
const CalcPage = Loadable(lazy(() => import("../pages/Calc/CalcPage")));

//USER INFO
const Profile = Loadable(lazy(() => import("../pages/auth/Profile")));
const Archivero = Loadable(lazy(() => import("../pages/auth/Archivero")));
const ContactListPage = Loadable(
  lazy(() => import("../pages/Contact/ContactList"))
);

// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Common/Home")));
const TermPage = Loadable(lazy(() => import("../pages/Common/Term")));
const PrivacyPage = Loadable(lazy(() => import("../pages/Common/Privacy")));
const AboutPage = Loadable(lazy(() => import("../pages/Common/About")));
const Page500 = Loadable(lazy(() => import("../pages/Common/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Common/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Common/Page404")));
const ContactUsPage = Loadable(lazy(() => import("../pages/Common/ContactUs")));
