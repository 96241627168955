// @mui

// routes
// hooks
import useAuth from "../../hooks/useAuth";
// components
import Page from "../../components/Page";
// sections
import { Col, notification, Row, Spin, Typography } from "antd";
import { Form, Button } from "antd";
import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";
import bg from "../../assets/bg-comoF.png";
import logo from "../../assets/images/logo-1.webp";
import { Link, useNavigate } from "react-router-dom";
import { PATH_AUTH, PATH_PAGE } from "../../routes/paths";
import { useCallback } from "react";
import FormBuilder from "antd-form-builder";
import { getRules } from "../../utils/rules";
import { useGoogleLogin } from "@react-oauth/google";
import { CheckInput } from "../../components/form/CheckInput";

const { Title, Text } = Typography;
let hasChecked = false;

// ----------------------------------------------------------------------

export default function Login() {
  const { pending, login, loginFacebook, loginGoogle, loginTwitter } =
    useAuth();
  const navigate = useNavigate();
  const forceUpdate = FormBuilder.useForceUpdate();
  const [form] = Form.useForm();
  const handleFinish = useCallback((values) => {
    login(values.email, values.password, () => {
      navigate(PATH_PAGE.root);
    });
  });
  const handleFinishFailed = useCallback((values) => {
    notification.error({
      message: "Atención",
      description: "Por favor revise los campos, contiene datos incorrectos.",
    });
  });

  const meta = [
    {
      key: "email",
      placeholder: "Correo electronico",
      hasFeedback: true, // Show validation status icon in the right
      rules: getRules(["required", "email"]),
      widgetProps: {
        size: "large",
      },
    },

    {
      key: "password",
      placeholder: "Password",
      widget: "password",
      onChange: () => {
        if (form.isFieldTouched("confirmPassword")) {
          form.validateFields(["confirmPassword"]);
        }
      },
      rules: getRules(["required"]),
      widgetProps: {
        size: "large",
      },
    },

    {
      labelCol: 0,
      key: "accepted",
      label: "",
      colSpan: 6,
      widget: CheckInput,
      rules: getRules(["required"]),
      widgetProps: {
        size: "large",
        color: "#002448",
        label: "¿Aceptas nuestros términos y condiciones?",
      },
    },
  ];

  if (form.getFieldValue("accepted") !== undefined) {
    hasChecked = form.getFieldValue("accepted");
  }

  const responseGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      if (!hasChecked) {
        notification.info({
          message: "Información",
          description:
            "Debes aceptar nuestros términos y condiciones y nuestra política de privacidad antes de iniciar sesión",
        });
      } else {
        if (tokenResponse.access_token) {
          loginGoogle(tokenResponse.access_token, "", () => {
            navigate(PATH_PAGE.root);
          });
        }
      }
    },
  });

  const responseTwitter = (response) => {
  //  console.log(response);
    if (response.accessToken && response.profileObj) {
      loginTwitter(response.accessToken, response.profileObj.email, () => {
        navigate(PATH_PAGE.root);
      });
    }
  };

  const responseFacebook = () => {
    if (!hasChecked) {
      notification.info({
        message: "Información",
        description:
          "Debes aceptar nuestros términos y condiciones y nuestra política de privacidad antes de iniciar sesión",
      });
    } else {
      window.FB.login(
        function (response) {
          if (response.status === "connected") {
            let token = response.authResponse.accessToken;
            window.FB.api("/me?fields=id,email,name", function (response) {
              loginFacebook(token, response.email, () => {
                navigate(PATH_PAGE.root);
              });
            });
          }
         // console.log(response);
        },
        { scope: "email,public_profile" }
      );
    }
  };

  return (
    <Page title="Iniciar sesión">
      <Row
        style={{
          backgroundColor: "#EFAc51",
          textAlign: "center",
          padding: 10,
        }}
      >
        <Col className="box-auth" xl={8} lg={16} md={22} sm={22} xs={22}>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{
                paddingTop: 10,
              }}
            >
              <img alt="logo" width="auto" height="auto"
                onClick={() => navigate(PATH_PAGE.root)}
                style={{ maxWidth: 300 }}
                className="animate__animated animate__fadeInRight "
                src={logo}
              />
              <Title className="title-page"> Bienvenido a mi-lic</Title>
              <Title level={4} style={{ fontSize: 18 }}>
                Cuéntale tu problema o situación legal a uno de nuestros
                asesores, desde la comodidad de tu teléfono o computadora.
                Recibe la orientación legal que necesitas de forma rápida y a tu
                alcance.
              </Title>
              <Form
                form={form}
                onValuesChange={forceUpdate}
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailed}
              >
                <FormBuilder disabled={pending} meta={meta} form={form} />

                <Form.Item style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Button
                        onClick={responseFacebook}
                        disabled={pending}
                        htmlType="button"
                        className="button button-primary cta"
                        style={{
                          backgroundColor: "#3b5998",
                          border: "1px solid #3b5998",
                        }}
                      >
                        <FacebookOutlined className="social-icon" />
                      </Button>

                      <Button
                         onClick={() => {
                          if (!hasChecked) {
                            notification.info({
                              message: "Información",
                              description:
                                "Debes aceptar nuestros términos y condiciones y nuestra política de privacidad antes de iniciar sesión",
                            });
                          } else {
                            responseGoogle();
                          }
                        }}
                        disabled={pending}
                        htmlType="button"
                        className="button button-primary cta"
                        style={{
                          backgroundColor: "#DB4437",
                          border: "1px solid #DB4437",
                        }}
                      >
                        <GoogleOutlined className="social-icon" />
                      </Button>
                    </div>
                    <Button
                      disabled={pending}
                      type="primary"
                      htmlType="submit"
                      className="button button-primary cta"
                    >
                      Iniciar Sesión &nbsp;{pending && <Spin />}
                    </Button>
                    {/** 
                    <TwitterLogin
                      authCallback={responseTwitter}
                      consumerKey={process.env.REACT_APP_TWITTER_CONSUMER_KEY}
                      consumerSecret={
                        process.env.REACT_APP_TWITTER_CONSUMER_SECRET
                      }
                      children={
                        <Button
                          disabled={pending}
                          htmlType="button"
                          className="button button-primary cta"
                          style={{
                            backgroundColor: "#1DA1F2",
                            border: "1px solid #1DA1F2",
                          }}
                          icon={<TwitterOutlined className="social-icon" />}
                        >
                          Twitter
                        </Button>
                      }
                    />
                    */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Link to={PATH_AUTH.forgotPassword}>
                      <Text
                        type="primary"
                        underline
                        level={5}
                        className="text-dark"
                      >
                        ¿Has olvidado la contraseña?
                      </Text>
                    </Link>
                    &nbsp;/&nbsp;
                    <Link to={PATH_AUTH.register}>
                      <Text
                        type="primary"
                        underline
                        level={5}
                        className="text-dark"
                      >
                        Regístrate
                      </Text>
                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Page>
  );
}
