// @antd
import { Button, Col, Result, Row, Typography } from "antd";
import { useEffect } from "react";
import Page from "../../components/Page";
import { PATH_PAGE } from "../../routes/paths";
import { cleanStorage } from "../../utils/storage";

export default function CalcSuccessCreate() {
  useEffect(() => {
    cleanStorage("FORM");
    cleanStorage("selectedCard");
  }, []);

  return (
    <Page title="Mi-LIC | Cálculo Realizado">
      <Row>
        <Col style={{ margin: "auto" }} xl={12} lg={12} md={24} sm={24} xs={24}>
          <section
            style={{
              marginTop: 30,
              marginBottom: 30,
              padding: "4% 10% 4% 10%",
            }}
          >
            <div>
              <Result
                status="success"
                title="Datos enviados exitosamente"
                subTitle={
                  <>
                    <Typography.Text style={{ fontSize: 16 }}>
                      ¡Hola! Tu servicio fue realizado exitosamente.
                    </Typography.Text>
                    <br />
                    <Typography.Text style={{ fontSize: 16 }}>
                      Por favor revisa en tu correo electrónico tu bandeja de
                      entrada y/o correo no deseado para encontrar las
                      indicaciones enviada por nuestros abogados. Recuerda
                      registrarnos como una dirección segura.
                    </Typography.Text>
                    <br /> <br />
                    <Typography.Text style={{ fontSize: 16 }}>
                      ¡Gracias por tu confianza y por utilizar los servicios de
                      mi-lic!
                    </Typography.Text>
                  </>
                }
                extra={[
                  <Button href={"/"} type="primary" key="console">
                    <Typography.Text style={{ color: "#FFF" }}>
                      {" "}
                      Ir al inicio{" "}
                    </Typography.Text>
                  </Button>,

                  <Button href={PATH_PAGE.write_with_lawyer} key="buy">
                    Nueva consulta
                  </Button>,
                ]}
              />
            </div>
          </section>
        </Col>
      </Row>
    </Page>
  );
}
