import { notification } from "antd";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import avatar from "../assets/avatar.png";
import { PATH_PAGE } from "../routes/paths";
import { processActionService } from "../utils/axios";
import { isValidToken, setSession, setUserSession } from "../utils/jwt";
import { fromStorage, toStorage } from "../utils/storage";
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  fromModal: false,
  pending: false,
  user: null,
  serviceList: [],
  sections: [],
  pendingSection: false,
  listDoc: [],

  configure: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, fromModal } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      fromModal,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  UPDATE_ACCOUNT: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  UPDATE_ARCHIVE: (state, action) => {
    const { listDoc } = action.payload;
    return {
      ...state,
      listDoc: listDoc,
      pending: false,
    };
  },
  DELETE_DOC: (state, action) => {
    return {
      ...state,
      listDoc: state.listDoc.filter((e) => e.id !== action.payload.serviceID),
      pending: false,
    };
  },
  START: (state) => {
    return {
      ...state,
      pending: true,
    };
  },

  STOP: (state) => {
    return {
      ...state,
      pending: false,
    };
  },

  START_CONFIGURE: (state) => {
    return {
      ...state,
      pending: true,
    };
  },

  STOP_CONFIGURE: (state, action) => {
    const { configure } = action.payload;
    
    return {
      ...state,
      pending: false,
      configure: configure,
    };
  },
  STOP_SERVICES: (state, action) => {
    const { serviceList } = action.payload;

    const URL = {
      contract: PATH_PAGE.marketplace,
      document_for_reviewing: PATH_PAGE.review,
      consultation: PATH_PAGE.marketplace,
      contract: PATH_PAGE.marketplace,
    };

    //  console.log(serviceList);
    let item = [];
    Object.keys(serviceList).forEach((key) => {
      if (serviceList[key].category === null) {
        item.push({
          text: serviceList[key].name,
          url: serviceList[key].name,
        });
      }
    });
    return {
      ...state,
      serviceList: serviceList,
    };
  },
  START_SECTION: (state) => {
    return {
      ...state,
      pendingSection: true,
    };
  },

  STOP_SECTION: (state, action) => {
    const { sections } = action.payload;

    return {
      ...state,
      sections: sections,
      pendingSection: false,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = fromStorage("accessToken");

        //"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZjg3MDdjYTk0NGYyZjFlMGQ0MGZjYjIwN2VkOTY4NTUyYmYwMjQxODY0NmUwMmZmMGIxMDIxMjVhOTVmNWRlZmE0MWZjNjZlOWYzMWI5NzkiLCJpYXQiOjE2NzA0NjYzMjcuMzU0NTAxLCJuYmYiOjE2NzA0NjYzMjcuMzU0NTA3LCJleHAiOjE2ODYxODc1MjcuMzQ5NTQsInN1YiI6IjIiLCJzY29wZXMiOltdfQ.LNy9Jt5LxIq6NQ9ouCRSPf05m8G08lnkix1pE-36pUz8-hlfjJYxh2hxEQQ8UNhgz8T5PXrmw33_ydWKeqNovdG3dmG3_WnKWouV53vOtuugCJXm1AisWH-fE-URjgbIf0dxy3jdFZ-3hUsuDXZrY3_WJpNZD1z9xUlEp_-lSnI9_QGvD4OVm4RS20TMUIesfIDOdQhj0FmXMBklgfKWyIwOr0_ibTvzaiEnbLPQTv1QrRKaK91VcOWaUSZ8ssJ9GxUpBBiUbeYv8zI4h-WAkBfSr_omzsLNmeMF9YDpiqlqOKnE0OhBszCEj7srVKgkcfcPAUJ-DG5ifa-bU1a6qh1b5ZcqF4OmYGdhv5wzFpRAyPSg6E5jwuu0mQYS-2kHx1OWK34fmlFlSxHX8f6VaQTP7unx6DwsaGERWPwNfSnopnLLEst3lszgVDsQswY5wefz4lbmloh93e7VGRFBZrOOH89l7w00rr7SdNcgOgII1QxyqRRumolIcKNDkUMWWxzm1R6nhW_gEPAL5C05zVspYRFWiwK9eqVIRaPSKHZd6Tu1kglQkHGQOyADhQGB0iwVQJqrPFRHL-s9yRh3Krdd2rq4l45GubkyLuHwFy9-EcZmzU7zC5E1XPsCtRBN4e68CMpMJnC9bGZHFxJG-O9p8AyvC5OiaDekeDR2sys"
        const user = fromStorage("milic-userdata")
          ? JSON.parse(fromStorage("milic-userdata"))
          : // {"id":2,"name":"Yasiel Perez","email":"yasielpv1503@gmail.com","avatar":{},"cards":[]}
            null;
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          //const response = await axios.get("/api/account/my-account");
          //const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const createSession = (response, callback) => {
    const { success, message, result } = response;
    setSession(result.accessToken);
    setUserSession(result.user);
    dispatch({
      type: "LOGIN",
      payload: {
        isAuthenticated: true,
        user: {
          ...result.user,
          avatar:
            typeof result.user.avatar === "string"
              ? result.user.avatar
              : avatar,
        },
        fromModal: typeof callback === "function",
      },
    });
    setTimeout(() => {
      if (typeof callback === "function") callback(response);
      dispatch({
        type: "STOP",
      });
    }, 300);
  };

  const login = async (email, password, callback) => {
    dispatch({
      type: "START",
    });
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    processActionService("/login", "post", formData)
      .then((response) => createSession(response, callback))
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };
  const register = async (formData, callback) => {
    dispatch({
      type: "START",
    });
    processActionService("/register", "post", formData)
      .then((response) => createSession(response, callback))
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const forgotPassword = async (email, callback) => {
    dispatch({
      type: "START",
    });
    processActionService("/forgot-password", "post", { email })
      .then((response) => {
        callback(response);
        dispatch({
          type: "STOP",
        });
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };
  const changePassword = async (formData, callback) => {
    dispatch({
      type: "START",
    });
    processActionService("/reset-password", "post", formData)
      .then((response) => {
        callback();
        dispatch({
          type: "STOP",
        });
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const resetPassword = async (formData, callback) => {
    dispatch({
      type: "START",
    });
    processActionService("/profile/password", "post", formData)
      .then((response) => {
        callback(response);
        dispatch({
          type: "STOP",
        });
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const fetchAccount = async () => {
    //console.log("fetchAccount");
    dispatch({
      type: "START",
    });
    processActionService("/profile/me", "get")
      .then((response) => {
        const { result, success } = response;

        let account = result;
        if (success) {
          dispatch({
            type: "UPDATE_ACCOUNT",
            payload: {
              user: {
                ...account,
                avatar:
                  typeof account.avatar === "string" ? account.avatar : avatar,
              },
            },
          });
          toStorage("milic-userdata", JSON.stringify(account));
        }
        dispatch({
          type: "STOP",
        });
      })
      .catch(() => {
        setSession(null);
        setUserSession(null);
        dispatch({ type: "LOGOUT" });

        dispatch({
          type: "STOP",
        });
      });
  };

  const saveAccount = async (formData, avatar, callback) => {
    dispatch({
      type: "START",
    });
    let form = new FormData();
    if (avatar) form.append("avatar", avatar);

    form.append("email", formData.email);
    form.append("name", formData.name);

    processActionService("/profile/me", "post", form, true)
      .then((response) => {
        const { success, message, result } = response;

        if (success) {
          notification.success({
            message: "Cuenta actualizada",
            description:
              "La cuenta del usuario " +
              result.email +
              " fue actualizada con éxito.",
          });
          dispatch({
            type: "UPDATE_ACCOUNT",
            payload: {
              user: {
                ...result,
                avatar:
                  typeof result.avatar === "string" ? result.avatar : avatar,
              },
            },
          });
          toStorage("milic-userdata", JSON.stringify(result));
        }
        dispatch({
          type: "STOP",
        });
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  //USER ARCHIVERO
  const fetchDocs = async () => {
    dispatch({
      type: "START",
    });
    processActionService("/archives", "get")
      .then((response) => {
        const { result, success } = response;
        if (success) {
          dispatch({
            type: "UPDATE_ARCHIVE",
            payload: {
              listDoc: result,
            },
          });
        }
        dispatch({
          type: "STOP",
        });
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const deleteDoc = async (serviceID, serviceType) => {
    dispatch({
      type: "START",
    });
    processActionService(`/archives/${serviceType}/${serviceID}`, "delete")
      .then((response) => {
        if (response.success) {
          notification.success({
            message: "Documento eliminada",
            description: "El documento fue eliminado con éxito.",
          });

          dispatch({
            type: "DELETE_DOC",
            payload: { serviceID },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const loginFacebook = async (token, email, callback) => {
    dispatch({
      type: "START",
    });
    const formData = new FormData();
    formData.append("provider", "facebook");
    formData.append("access_token", token);
    formData.append("email", email);
    processActionService("/social-login", "post", formData)
      .then((response) => createSession(response, callback))
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const loginGoogle = async (token, email, callback) => {
    dispatch({
      type: "START",
    });
    const formData = new FormData();
    formData.append("provider", "google");
    formData.append("access_token", token);
    formData.append("email", email);
    processActionService("/social-login", "post", formData)
      .then((response) => createSession(response, callback))
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const loginTwitter = async (token, email, callback) => {
    dispatch({
      type: "START",
    });
    const formData = new FormData();
    formData.append("provider", "twitter");
    formData.append("access_token", token);
    formData.append("email", email);
    processActionService("/social-login", "post", formData)
      .then((response) => createSession(response, callback))
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const fetchServices = async () => {
    // console.log("");
    dispatch({
      type: "START",
    });
    processActionService("/service-categories", "get")
      .then((response) => {
        const { result, success } = response;
        if (success) {
          dispatch({
            type: "STOP_SERVICES",
            payload: {
              serviceList: result,
            },
          });
        }
        dispatch({
          type: "STOP",
        });
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const sendContactUsMenssage = async (email, message, callback) => {
    dispatch({
      type: "START",
    });
    const formData = new FormData();
    formData.append("email", email);
    formData.append("comment", message);
    processActionService("/contact-us", "post", formData)
      .then((response) => {
        notification.success({
          message: "Información",
          description: "Mensaje enviado exitosamente.",
        });
        callback();
      })
      .catch(() =>
        dispatch({
          type: "STOP",
        })
      );
  };

  const deleteAccount = async (callback) => {
    dispatch({
      type: "START",
    });
    const user = fromStorage("milic-userdata")
      ? JSON.parse(fromStorage("milic-userdata"))
      : null;
    if (user) {
      let ID = user.id;
      processActionService(`/users/${ID}`, "delete", {})
        .then((response) => {
          notification.success({
            message: "Información",
            description: "Cuenta eliminada exitosamente.",
          });

          setSession(null);
          setUserSession(null);
          dispatch({ type: "LOGOUT" });
          dispatch({
            type: "STOP",
          });
          callback();
        })
        .catch(() =>
          dispatch({
            type: "STOP",
          })
        );
    }
  };
  const fetchConfiguration = async (service, callback) => {
    dispatch({
      type: "START_CONFIGURE",
    });
    processActionService(`/configurations/${service}`, "get", {})
      .then((response) => {
        console.log(response.result);
        dispatch({
          type: "STOP_CONFIGURE",
          payload: {
            configure: response.result,
          },
        });
        callback(response.result);
      })
      .catch(() => {});
  };

  const fetchSections = async (callback) => {
    dispatch({
      type: "START_SECTION",
    });

    processActionService(`/sections`, "get", {})
      .then((response) => {
        let activeItems = response.result.filter((e) => e.active == 1);

        let groups = [];
        let items = [];
        activeItems
          .map((e) => e.group)
          .filter((e) => e != null)

          .forEach((e) => {
            if (!groups.includes(e)) groups.push(e);
          });

        activeItems.forEach((element) => {
          if (!element.group)
            items.push({
              ...element,
              uri: element.uri === "null" ? "/" : element.uri,
              subItems: [],
            });
          else {
            if (groups.includes(element.group)) {
              let index = groups.findIndex((e) => e === element.group);
              groups.splice(index, 1);
              let subItems = activeItems.filter(
                (e) => e.group === element.group
              );
              items.push({ ...subItems[0], subItems: subItems });
            }
          }
        });
        
        dispatch({
          type: "STOP_SECTION",
          payload: {
            sections: items,
          },
        });
      })
      .catch(() => {});
  };

  const logout = async () => {
    setSession(null);
    setUserSession(null);
    fetchSections();
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        saveAccount,
        forgotPassword,
        changePassword,
        resetPassword,
        fetchDocs,
        fetchAccount,
        deleteDoc,
        loginFacebook,
        loginGoogle,
        loginTwitter,
        fetchServices,
        sendContactUsMenssage,
        deleteAccount,
        fetchConfiguration,
        fetchSections,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
